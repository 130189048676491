import { default as indexpO9XbOZJNyMeta } from "/home/runner/work/levelupdx-app/levelupdx-app/pages/index.vue?macro=true";
import { default as registerRKwQoIQnNpMeta } from "/home/runner/work/levelupdx-app/levelupdx-app/pages/register.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: indexpO9XbOZJNyMeta || {},
    component: () => import("/home/runner/work/levelupdx-app/levelupdx-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: registerRKwQoIQnNpMeta || {},
    component: () => import("/home/runner/work/levelupdx-app/levelupdx-app/pages/register.vue").then(m => m.default || m)
  }
]